import React from 'react';

import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Spinner from 'react-bootstrap/Spinner';

import moment from 'moment';

import { ProductsController } from '../../api/ProductsController';
import { OrderDetail } from './OrderDetail';

export class AdminOrderList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            fulfilledOrders: [],
            openCarts: [],
            products: [],
            isLoadingOrders: false,
            viewingOrder: undefined,
        };
    }

    componentDidMount() {
        this._fetchOrders();
        this._fetchProducts();
    }

    _fetchProducts = () => {
        ProductsController.listProducts('all').then((results) => {
            const { products } = results;

            if (products && products.length) {
                this.setState({
                    products,
                });
            }
        });
    }

    _fetchOrders = () => {
        const orders = [];
        const openCarts = [];
        const fulfilledOrders = [];

        ProductsController.listOrders().then((results) => {
            Object.keys(results).forEach((key) => {
                const order = results[key];
                if (order.checkout) {
                    if (order.fulfilled) {
                        fulfilledOrders.push(order);
                    } else {
                        orders.push(order);
                    }
                } else {
                    openCarts.push(order);
                }
            });

            this.setState({
                orders,
                openCarts,
                fulfilledOrders,
                isLoadingOrders: false,
            });
        });
    }

    _handleOrderClicked = (orderId) => {
        this.setState({
            viewingOrder: orderId,
        });
    }

    _handleOrderFulfill = async (orderId) => {
        await ProductsController.fulfillOrder(orderId);
        // the order now needs to be in fulfilled

        this.setState({
            viewingOrder: undefined,
        }, () => {
            this._fetchOrders();
        });
    }

    _handleDeleteOrderClicked = async (orderId) => {
        await ProductsController.deleteCart(orderId);

        this.setState({
            viewingOrder: undefined,
        }, () => {
            this._fetchOrders();
        });
    }

    _renderOrderDetails = (orderId) => {
        if (!orderId) {
            return null;
        }

        const { orders, openCarts, fulfilledOrders } = this.state;
        const allOrders = [
            ...orders,
            ...openCarts,
            ...fulfilledOrders,
        ];

        const order = allOrders.find((o) => o.cartId === orderId);

        return (
            <>
                <h2>Products</h2>
                <OrderDetail
                    products={this.state.products}
                    orderId={orderId}
                />

                <ButtonToolbar>
                    <Button onClick={() => this.setState({ viewingOrder: undefined })}>
                        Back
                    </Button>

                    <Button
                        variant="danger"
                        onClick={ () => this._handleDeleteOrderClicked(orderId) }
                    >
                        Delete
                    </Button>

                    {
                        order.checkout && !order.fulfilled &&
                        <Button
                            variant="success"
                            onClick={ () => this._handleOrderFulfill(orderId) }
                        >
                            Fulfill
                        </Button>
                    }

                </ButtonToolbar>
            </>
        )
    }

    _renderOrders = (orders, openCarts, fulfilledOrders, viewingOrder) => {
        if (!openCarts || !orders || !fulfilledOrders || viewingOrder) {
            return null;
        }

        const { products } = this.state;
        if (products.length === 0) {
            return null;
        }

        let ordersSubTotal = 0;
        let openCartsSubTotal = 0;
        let fulfilledOrdersSubTotal = 0;

        let openCartsJsx;
        let ordersJsx;
        let fulfilledOrdersJsx;

        const listItemStyle = {
            display: 'flex',
            justifyContent: 'space-between',
        };

        openCartsJsx = openCarts.length ? openCarts.map((openCart) => {
            console.log('openCart', openCart);

            let cartTotal = 0;
            openCart.products.forEach((product) => {
                const productDetail = products.find((pro) => pro.productId === parseInt(product.productId));

                if (productDetail) {
                    openCartsSubTotal += productDetail.price;
                    cartTotal += productDetail.price;
                }
            });

            return (
                <ListGroup.Item key={openCart.cartId} style={listItemStyle}>
                    <Button
                        variant="outline-success"
                        size="sm"
                        onClick={() => this._handleOrderClicked(openCart.cartId)}
                    >
                        View Cart
                    </Button>
                    <span><strong>Last Updated:</strong> { moment.unix(openCart.lastUpdated).format('MMMM Do YYYY, h:mm:ss a') }</span>
                    <span>
                        <strong>Total: </strong> ${cartTotal.toFixed(2)}/biweekly
                    </span>
                </ListGroup.Item>
            );
        })
        : <span><em>No open carts at this time</em></span>;

        ordersJsx = orders.length ? orders.map((order) => {
            let orderTotal = 0;

            order.products.forEach((product) => {
                const productDetail = products.find((pro) => pro.productId === parseInt(product.productId));

                if (productDetail) {
                    ordersSubTotal += productDetail.price;
                    orderTotal += productDetail.price;
                }
            });

            return (
                <ListGroup.Item key={order.cartId} style={listItemStyle}>
                    <Button
                        variant="outline-success"
                        size="sm"
                        onClick={() => this._handleOrderClicked(order.cartId)}
                    >
                        View Order
                    </Button>

                    <span>
                        <strong>Name:</strong> {order.contact && `${order.contact.firstName} ${order.contact.lastName}`}
                    </span>

                    <span>
                        <strong>Last Updated:</strong> { moment(order.lastUpdated).format('MMMM Do YYYY, h:mm:ss a') }
                    </span>

                    <span>
                        <strong>Total: </strong> ${orderTotal.toFixed(2)}/biweekly
                    </span>
                </ListGroup.Item>
            );
        }) : <span><em>No orders at this time</em></span>;

        fulfilledOrdersJsx = fulfilledOrders.length ? fulfilledOrders.map((order) => {
            let orderTotal = 0;

            order.products.forEach((product) => {
                const productDetail = products.find((pro) => pro.productId === parseInt(product.productId));

                if (productDetail) {
                    fulfilledOrdersSubTotal += productDetail.price;
                    orderTotal += productDetail.price;
                }
            });

            return (
                <ListGroup.Item key={order.cartId} style={listItemStyle}>
                    <Button
                        variant="outline-success"
                        size="sm"
                        onClick={() => this._handleOrderClicked(order.cartId)}
                    >
                        View Order
                    </Button>

                    <span>
                        <strong>Name:</strong> {order.contact && `${order.contact.firstName} ${order.contact.lastName}`}
                    </span>

                    <span>
                        <strong>Last Updated:</strong> { moment(order.lastUpdated).format('MMMM Do YYYY, h:mm:ss a') }
                    </span>

                    <span>
                        <strong>Total: </strong> ${orderTotal.toFixed(2)}/biweekly
                    </span>
                </ListGroup.Item>
            );
        }) : <span><em>No fullfilled orders at this time</em></span>;

        return (
            <>
                <h2>Order list</h2>
                <ListGroup
                    variant="flush"
                >
                    <ListGroup.Item>
                        <h4>Orders</h4>
                        { ordersJsx}
                        <h6><strong>Total Order Potential:</strong> ${ordersSubTotal.toFixed(2)}/biweekly</h6>
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <h4>Open Carts</h4>
                        { openCartsJsx }
                        <h6><strong>Total Open Cart Potential:</strong> ${openCartsSubTotal.toFixed(2)}/biweekly</h6>
                    </ListGroup.Item>

                    <ListGroup.Item>
                        <h4>Fulfilled Orders</h4>
                        { fulfilledOrdersJsx }
                        <h6><strong>Total Fulfilled Orders Potential:</strong> ${fulfilledOrdersSubTotal.toFixed(2)}/biweekly</h6>
                    </ListGroup.Item>
                </ListGroup>
            </>
        );
    }

    render() {
        const { orders, openCarts, fulfilledOrders, products, viewingOrder } = this.state;

        if (!products.length) {
            return (
                <div style={{ margin: '80px 0'}}>
                    <Spinner />
                </div>
            );
        }

        return (
            <div style={{ margin: '80px 0'}}>
                { this._renderOrderDetails(viewingOrder) }
                { this._renderOrders(orders, openCarts, fulfilledOrders, viewingOrder) }
            </div>
        );
    }
}
