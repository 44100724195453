import * as React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

// User Routes
import { Home } from './containers/Home';
import { Cart } from './containers/Cart';
import { ProductDetails } from './containers/ProductDetails';
import { ProductCategory } from './containers/ProductCategory';

// Admin Routes
import { AdminOrder } from './containers/AdminOrders';

import { CartContextProvider } from './components/CartContext';
import { AdminProducts } from './containers/AdminProducts';

export class Router extends React.Component {
    render() {
        return (
            <CartContextProvider>
                <BrowserRouter>
                    <Route path="/" exact={true} component={Home} />
                    <Route path="/product/:productId" exact={true} component={ProductDetails} />
                    <Route path="/categories/:category" exact={true} component={ProductCategory} />
                    <Route path="/cart" exact={false} component={Cart} />

                    <Route path="/admin/orders" exact={true} component={AdminOrder} />
                    <Route path="/admin/products" exact={true} component={AdminProducts} />
                </BrowserRouter>
            </CartContextProvider>
        );
    }
}