/**
 * Fetch CartId from storage
 */
export const fetchCartIdFromStorage = () => {
    const cartId = window.localStorage.getItem('cartId', undefined);

    return cartId;
}

/**
 * Set Cart Id to storage
 */
export const setCartIdToStorage = (cartId) => {
    if (cartId) {
        window.localStorage.setItem('cartId', cartId);
    }
}

export const clearCartIdFromStorage = (cartId) => {
    if (cartId) {
        window.localStorage.removeItem('cartId');
    }
}