import React from 'react';
import Container from 'react-bootstrap/Container';
import { withRouter, Link } from 'react-router-dom';

import { MainNav } from '../MainNav';
import { HomeJumbotron } from '../HomeJumbo';
import { ProductCardList } from '../ProductCardList';

import { ProductsController } from '../../api/ProductsController';
import { SiteFooter } from '../SiteFooter';

class HomeComponentClass extends React.Component {
    constructor(props, state) {
        super(props, state);

        this.state = {
            products: [],
            categories: [],
            variant: undefined,
        };

        this._fetchProducts();
        this._fetchCategories();
    }

    _fetchProducts = () => {
        ProductsController.listProducts('featured').then((result) => {
            let {products, variant} = result;

            if (!result || !result.products) {
                products = [];
            }

            this.setState({
                variant,
                products,
            });
        });
    }

    _fetchCategories = () => {
        ProductsController.listCategories().then((result) => {
            this.setState({
                categories: result,
            });
        });
    }

    _handleProductCardClicked = (product) => {
        const { history } = this.props;
        history.push(`/product/${product.productId}`);
    }

    _handleHomeClicked = () => {
        const { history } = this.props;
        history.push(`/`);
    }

    _renderCategories = (categories) => {
        if (categories.length) {
            return (
                <>
                    <h2>Categories</h2>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        {
                            categories.map((category) => (
                                <Link
                                    to={`/categories/${category.toLowerCase()}`}
                                    key={category}
                                >
                                    <h4>{category.replace(/-/g, ' ')}</h4>
                                </Link>
                            ))
                        }
                    </div>
                </>
            )
        }
    }

    _renderFeaturedProducts = (products) => {
        if (products.length) {
            return (
                <>
                    <h2>Featured Products</h2>
                    <ProductCardList
                        products={products}
                        onClick={this._handleProductCardClicked}
                        isTextVisible={false}
                    />
                </>
            );
        }

        return <div>No products yet</div>
    }

    render() {
        return (
            <>
                <MainNav />

                <HomeJumbotron />

                <Container style={{ padding: '32px 0'}}>
                    {this._renderFeaturedProducts(this.state.products)}
                </Container>

                <Container style={{ padding: '32px 0'}}>
                    {this._renderCategories(this.state.categories)}
                </Container>

                <SiteFooter />
            </>
        )
    }
}

export const HomeComponent = withRouter(HomeComponentClass);