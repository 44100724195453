import React from 'react';

import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import { withRouter } from 'react-router-dom';

import { MainNav } from '../MainNav';
import { SiteFooter } from '../SiteFooter';

import { fetchCartIdFromStorage } from '../../api/Utils';
import { ProductsController } from '../../api/ProductsController';
import { CartContext } from '../CartContext';

import Button from 'react-bootstrap/Button';
import { CartCheckout } from '../CartCheckout';

class CartComponentClass extends React.Component {
    static contextType = CartContext;

    constructor(props, state) {
        super(props, state);

        this.state = {
            errorMessage: undefined,
            order: undefined,
            productDetailsCollection: [],
            subTotal: 0,
            showDeleteDialog: false,
            isCheckout: false,
        }
    }

    componentWillMount() {
        const cartId = fetchCartIdFromStorage();
        if (cartId) {
            this._fetchCartContents(cartId);
        }
    }

    _fetchCartContents = (cartId) => {
        ProductsController.getCart(cartId)
            .then(async (results) => {
                if (results.message) {
                    this.setState({
                        errorMessage: results.message,
                    });
                    return;
                }

                const { order } = results;
                if (!order) {
                    this.setState({
                        errorMessage: 'There is nothing in your cart.',
                    });
                    return;
                }

                const { products } = order;
                if (!products) {
                    return;
                }

                await products.forEach(async (product) => {
                    const { productId } = product;
                    ProductsController.getProductById(productId).then((productDetails) => {
                        const { product } = productDetails;

                        this.setState({
                            productDetailsCollection: [
                                ...this.state.productDetailsCollection,
                                product,
                            ],
                            order,
                        });
                    });
                });
            })
            .catch((err) => {
                console.log('err', err);
            });
    }

    _handleCheckoutComplete = () => {
        // clear context
        this.setState({
            isCheckout: false,
            productDetailsCollection: [],
            order: undefined,
        });

        this.context.clearCart();

        // redirect to home or a special screen of some sort
        this.props.history.push('/');
    }

    _handleDeleteItem = (index) => {
        ProductsController
            .deleteIndexFromCart(index, fetchCartIdFromStorage())
            .then(() => {
                ProductsController
                .getCart(fetchCartIdFromStorage())
                .then((result) => {
                    const { length, order } = result;
                    const { cartId } = order;
                    this.context.updateCart(cartId, length);

                    this.setState({
                        showDeleteDialog: true,
                        order,
                    }, () => {
                        this._fetchCartContents(cartId);
                    });
                });
            }).catch((err) => {
            });
    }

    _renderOrder = (order, productDetailsCollection) => {
        if (!productDetailsCollection || !order) {
            return null;
        }

        const { products } = order;

        if (!products.length) {
            return <div>No items in your cart</div>
        }

        let subTotal = 0;
        products.forEach((product) => {
            const productDetail = productDetailsCollection.find((pro) => pro.productId === parseInt(product.productId));

            if (productDetail) {
                subTotal += productDetail.price;
            }
        });

        const productJsx = products.map((product, key) => {
            const productDetails = productDetailsCollection.find((pro) => pro.productId === parseInt(product.productId));

            if (!productDetails) {
                return null;
            }

            return (
                <ListGroup.Item
                    key={`item_${key}`}
                    style={{
                        display: 'flex', justifyContent: 'space-between',
                    }}
                >
                    <div style={{ width: '10%' }}>
                        <img
                            src={productDetails.imageUrl}
                            style={{ width: 'auto', height: 64 }}
                            alt={productDetails.title}
                        />
                    </div>
                    <div style={{ width: '40%' }}>{ productDetails.title }</div>
                    <div style={{ width: '25%' }}>
                        {
                            product.options && Object.keys(product.options).map((key) => (
                                <span key={`product_${key}`}><strong>{key.toUpperCase()}</strong>: {product.options[key]} <br/></span>
                            ))
                        }
                    </div>
                    <div style={{ width: '15%' }}>
                        <strong>${ productDetails.price }<span className="units">/bi-weekly</span></strong>
                    </div>
                    <div style={{ width: '10%' }}>
                        <Button
                            onClick={() => this._handleDeleteItem(key)}
                            size="sm"
                            variant="outline-danger"
                        >
                            DELETE
                        </Button>
                    </div>
                </ListGroup.Item>
            );
        });

        return (
            <>
            <ListGroup
                variant="flush"
            >
                {productJsx}
            </ListGroup>

            <div>
                <strong>Subtotal:</strong> ${ subTotal.toFixed(2)}<span className="units">/bi-weekly</span>
            </div>

            {
                !this.state.isCheckout &&
                <div>
                    <Button
                        onClick={() => this.setState({ isCheckout: true })}
                    >
                        Checkout
                    </Button>
                </div>
            }
            </>
        );
    }

    _renderDeleteDialog = (isShown) => {
        const handleClose = () => this.setState({
            showDeleteDialog: false,
        });

        return (
            <Modal
                show={isShown}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Deleted Item
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Item Removed from your cart.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        onClick={handleClose}
                    >
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    _renderCheckout = () => {
        const { isCheckout } = this.state;

        return isCheckout ? (
            <div>
                <CartCheckout
                    cartId={fetchCartIdFromStorage()}
                    onCheckoutComplete={this._handleCheckoutComplete}
                />
            </div>
        ) : null;
    }

    render() {
        const { showDeleteDialog, productDetailsCollection, order, errorMessage } = this.state;

        return (
            <>
                <MainNav />

                { this._renderDeleteDialog(showDeleteDialog) }

                <Container
                    style={{ minHeight: '80vh' }}
                >
                    <h2>Your Cart</h2>

                    {
                        errorMessage
                        ?
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}>
                            <h6>{ errorMessage }</h6>
                        </div>
                        : this._renderOrder(order, productDetailsCollection)
                    }

                    {
                        this._renderCheckout()
                    }

                </Container>
                <SiteFooter />
            </>
        );
    }
}

export const CartComponent = withRouter(CartComponentClass);