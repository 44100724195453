import React from 'react';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';

export class HomeJumbotron extends React.Component {
    render() {
        return (
            <Jumbotron fluid>
                <Container>
                    <h1>Sport Shopping</h1>
                    <p>
                        Get the best sportswear for less!
                    </p>
                </Container>
            </Jumbotron>
        )
    }
}