import React from 'react';
import Container from 'react-bootstrap/Container';

import { SiteFooter } from '../SiteFooter';
import { HomeJumbotron } from '../HomeJumbo';

import { AdminNav } from './Nav';
import { AdminOrderList } from './OrderList';

export class AdminOrderComponent extends React.Component {
    render() {
        return (
            <>
                <AdminNav
                    active="orders"
                />
                <HomeJumbotron />

                <Container>
                    <AdminOrderList />
                </Container>

                <SiteFooter />
            </>
        );
    }
}
