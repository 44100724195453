import React from 'react';

import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import { withRouter } from 'react-router-dom';

import { CartContext } from '../CartContext';

class CartNavComponent extends React.Component {
    constructor(props, state) {
        super(props, state);
    }

    _handleCartButtonClicked = () => {
        const { history } = this.props;
        history.push(`/cart`);
    }

    render() {
        return (
            <CartContext.Consumer>
            {(context) => {
                const { cart } = context;

                if (!cart || !cart.qty) {
                    return null;
                }

                // TODO: this *could* be a link...
                return (
                    <Button
                        variant="primary"
                        onClick={this._handleCartButtonClicked}
                    >
                        Cart <Badge variant="light">{cart.qty}</Badge>
                    </Button>
                );
            }}
        </CartContext.Consumer>
        );
    }
}

export const CartNav = withRouter(CartNavComponent);