import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CardDeck from 'react-bootstrap/CardGroup';

export class ProductCardList extends React.Component {
    constructor(props, state) {
        super(props, state);
    }

    render() {
        const { products, isTextVisible } = this.props;

        const productJsx = products.map((product) => {
            const { title, text, imageUrl, productId, price } = product;
            const width = 240;

            return (
                <Card
                    key={productId}
                    style={{ width: width, minWidth: width, maxWidth: width, marginBottom: 2 }}
                >
                    <Card.Img
                        variant="top"
                        src={imageUrl}
                    />
                    <Card.Body>
                        <Card.Title>{title}</Card.Title>
                        {
                            isTextVisible &&
                                <Card.Text>
                                    {text}
                                </Card.Text>
                        }
                    </Card.Body>

                    <Card.Footer>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                alignItems: 'baseline',
                            }}
                        >
                            <span>
                                ${ price.toFixed(2) }<span className="units">/bi-weekly</span>
                            </span>

                            <Button variant="primary" onClick={() => this.props.onClick(product)}>Details</Button>
                        </div>
                    </Card.Footer>
                </Card>
            )
        });

        return (
            <CardDeck
                style={{
                    justifyContent: 'center',
                }}
            >
                {productJsx}
            </CardDeck>
        );
    }
}
