import React from 'react';

import ListGroup from 'react-bootstrap/ListGroup';

import { ProductsController } from '../../api/ProductsController';

export class OrderDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            order: {
                contact: {},
                products: [],
            },
        };
    }

    componentWillMount() {
        const { orderId } = this.props;
        this._loadOrderDetails(orderId);
    }

    _loadOrderDetails = async (orderId) => {
        if (!orderId) {
            return;
        }

        const orderDetailResult = await ProductsController.getCart(orderId);
        const { order } = orderDetailResult;

        this.setState({
            order,
        });
    }

    _renderDetails = (contactDetails) => {
        if (!contactDetails) {
            return null;
        }

        const {
            firstName,
            lastName,
            email,
            phone,
            addr1,
            addr2,
            city,
            region,
            postal,
          } = contactDetails;

        return (
            <>
                <h6>Contact Info</h6>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <div>
                            { firstName } {lastName}
                        </div>
                        <div>
                            { addr1 } { addr2 }<br/>
                            { city }, { region }<br/>
                            { postal }
                        </div>
                        <div>
                            <strong>Phone:</strong> { phone }, <strong>Email:</strong> <a href={`mailto:`}>{ email } </a>
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </>
        )
    }


    _renderOrder = (productsInOrder) => {
        const { products } = this.props;

        if (!productsInOrder || !productsInOrder.length) {
            return <div><em>No products in order.</em></div>;
        }

        let subTotal = 0;
        productsInOrder.forEach((product) => {
            const productDetail = products.find((pro) => pro.productId === parseInt(product.productId));

            if (productDetail) {
                subTotal += productDetail.price;
            }
        });

        const productJsx = productsInOrder.map((product, key) => {
            const productDetails = products.find((pro) => pro.productId === parseInt(product.productId));

            if (!productDetails) {
                return null;
            }

            return (
                <ListGroup.Item
                    key={`item_${key}`}
                    style={{
                        display: 'flex', justifyContent: 'space-between',
                    }}
                >
                    <div style={{ width: '10%' }}>
                        <img
                            src={productDetails.imageUrl}
                            style={{ width: 'auto', height: 64 }}
                            alt={productDetails.title}
                        />
                    </div>
                    <div style={{ width: '40%' }}>{ productDetails.title }</div>
                    <div style={{ width: '25%' }}>
                        {
                            product.options && Object.keys(product.options).map((key) => (
                                <span key={`product_${key}`}><strong>{key.toUpperCase()}</strong>: {product.options[key]} <br/></span>
                            ))
                        }
                    </div>
                    <div style={{ width: '15%' }}>
                        <strong>${ productDetails.price }<span className="units">/bi-weekly</span></strong>
                    </div>
                    <div style={{ width: '10%' }}>
                        {/* <Button
                            onClick={() => this._handleDeleteItem(key)}
                            size="sm"
                            variant="outline-danger"
                        >
                            DELETE
                        </Button> */}
                    </div>
                </ListGroup.Item>
            );
        });

        return (
            <>
                <h6>Products</h6>
                <ListGroup
                    variant="flush"
                >
                    {productJsx}
                </ListGroup>

                <div>
                    <strong>Subtotal:</strong> ${ subTotal.toFixed(2)}<span className="units">/bi-weekly</span>
                </div>
            </>
        );
    }

    render() {
        const { order } = this.state;

        return (
            <>
            <h4>Order Details</h4>
            { this._renderDetails(order.contact) }
            { this._renderOrder(order.products) }
            </>
        );
    }
}