import React from 'react';

import { ProductCategoryComponent } from '../components/ProductCategory';

export class ProductCategory extends React.Component {
    category = null;

    constructor(props, state) {
        super(props, state);

        const { match } = props;
        const { params } = match;
        const { category } = params;
        if (category) {
            this.category = category;
        }
    }

    render() {
        return (
            <ProductCategoryComponent
                category={this.category}
            />
        );
    }
}