import React from 'react';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { ProductsController } from '../../api/ProductsController';

export class ProductsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            products: [],
            categories: [],
            activeCategory: undefined,
            viewingProduct: undefined,
            editingProduct: {},
            newProduct: undefined,
            isSavingProduct: false,
            showAddDialog: false,
            showAddCategoryDialog: false,
            newCategory: undefined,
        };
    }

    componentDidMount() {
        this._fetchProductsAndCategories();
    }

    _fetchProductsAndCategories = () => {
        ProductsController.listProducts('all').then(async (results) => {
            const { products } = results;
            console.log('all products', products);
            const categories = await ProductsController.listCategories();

            this.setState({
                products,
                activeCategory: categories[0],
                categories,
            });
        });
    }

    _handleProductClicked = (clickedProduct) => {
        ProductsController.getProductById(clickedProduct.productId).then((result) => {
            console.log('clicked product result', result);
            const { product } = result;

            if (product) {
                this.setState({
                    viewingProduct: product,
                    editingProduct: {
                        options: product.options || {},
                    },
                });
            }
        });
    }

    _handleProductDelete = async (productId) => {
        this.setState({
            isSavingProduct: true
        });

        ProductsController.deleteProductById(productId).then((_) => {
            this.setState({
                viewingProduct: undefined,
                editingProduct: undefined,
                isSavingProduct: false,
            }, () => {
                this._fetchProductsAndCategories();
            });
        });
    }

    _handleProductSaved = async (productId, changes) => {
        this.setState({
            isSavingProduct: true
        });

        debugger;
        ProductsController.updateProductById(productId, changes).then((_) => {
            this.setState({
                viewingProduct: undefined,
                editingProduct: undefined,
                isSavingProduct: false,
            }, () => {
                this._fetchProductsAndCategories();
            });
        });
    }

    _handleProductAdded = async (newProduct) => {
        ProductsController.addProduct(newProduct).then((_) => {
            this.setState({
                viewingProduct: undefined,
                editingProduct: undefined,
                isSavingProduct: false,
                showAddDialog: false,
                newProduct: undefined,
            }, () => {
                this._fetchProductsAndCategories();
            });
        });
    }

    _handleCategoryAdded = (newCategory) => {
        ProductsController.addCategory(newCategory).then((_) => {
            this.setState({
                viewingProduct: undefined,
                editingProduct: undefined,
                isSavingProduct: false,
                showAddDialog: false,
                showAddCategoryDialog: false,
            }, () => {
                this._fetchProductsAndCategories();
            });
        });
    }

    _renderNewProduct = (newProduct, selectedCategory, showAddDialog) => {
        const newProductJsx = (
            <>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridImageUrl">
                            <Form.Label>Image URL</Form.Label>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridImage">
                                    <img
                                        style={{ height: 240, width: 'auto' }}
                                        src={newProduct && newProduct.imageUrl ? newProduct.imageUrl : null}
                                    />
                                </Form.Group>
                            </Form.Row>

                            <Form.Control
                                placeholder="http://..."
                                onChange={(e) => {
                                    this.setState({
                                        newProduct: {
                                            ...newProduct,
                                            imageUrl: e.target.value,
                                        }
                                    });
                                }}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                placeholder="Title"
                                onChange={(e) => this.setState({
                                    newProduct: {
                                        ...newProduct,
                                        title: e.target.value,
                                    }})
                                }
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridText">
                            <Form.Label>Text</Form.Label>
                            <Form.Control
                                placeholder="Text"
                                as="textarea"
                                rows="3"
                                onChange={(e) => this.setState({
                                    newProduct: {
                                        ...newProduct,
                                        text: e.target.value,
                                    }})
                                }
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPrice">
                            <Form.Label>Price</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    placeholder="Price"
                                    type="number"
                                    step="0.01"
                                    onChange={(e) => this.setState({
                                        newProduct: {
                                            ...newProduct,
                                            price: parseFloat(e.target.value),
                                        }})
                                    }
                                />
                            </InputGroup>

                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridCategory">
                            <Form.Label>Category</Form.Label>
                            <Form.Control as="select"
                                onChange={(e) => this.setState({ newProduct: {...newProduct, category: e.target.value } })}
                            >
                                <option value={undefined}>Choose...</option>
                                { this.state.categories.map((cat) => (
                                    <option
                                        key={`opt_${cat}`}
                                        value={cat}
                                        selected={cat === selectedCategory}
                                    >
                                        {cat.replace(/-/g, ' ')}
                                    </option>
                                    ))
                                }
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </>
        );

        return (
            <>
                <Button
                    onClick={() => this.setState({ showAddDialog: true, newProduct: { category: selectedCategory } })}
                >
                    Add new product
                </Button>

                <Modal
                    size="lg"
                    show={showAddDialog}
                    onHide={() => this.setState({ showAddDialog: false })}
                >
                    <Modal.Header closeButton>
                        Add New Product
                    </Modal.Header>
                    <Modal.Body>
                        { newProductJsx }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({showAddDialog: false})}>Close</Button>
                        <Button variant="primary" onClick={() => this._handleProductAdded(newProduct)}>Add Product</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    _renderAddNewCategory = (showAddCategoryDialog, newCategory) => {
        const newCategoryJsx = (
            <>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridTitle">
                            <Form.Label>Category Name</Form.Label>
                            <Form.Control
                                placeholder="Category Name"
                                onChange={(e) => this.setState({
                                    newCategory: e.target.value,
                                })
                                }
                            />
                        </Form.Group>
                    </Form.Row>
                </Form>
            </>
        );

        return (
            <>
                <Button
                    onClick={() => this.setState({ showAddCategoryDialog: true })}
                >
                    Add new Category
                </Button>
                <Modal
                    size="lg"
                    show={showAddCategoryDialog}
                    onHide={() => this.setState({ showAddCategoryDialog: false })}
                >
                    <Modal.Header closeButton>
                        Add New Category
                    </Modal.Header>
                    <Modal.Body>
                        { newCategoryJsx }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({showAddCategoryDialog: false})}>Close</Button>
                        <Button variant="primary" onClick={() => this._handleCategoryAdded(newCategory)}>Add Category</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    _renderSelectedProductDetails = (product, editingProduct) => {
        // a nice form
        if (!product) {
            return null;
        }

        const { productId, category, text, title, price, imageUrl } = product;
        let { options } = editingProduct;
        console.log('options', options);

        return (
            <>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridImageUrl">
                            <Form.Label>Image URL</Form.Label>

                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridImage">
                                    <img
                                        style={{ height: 240, width: 'auto' }}
                                        src={editingProduct && editingProduct.imageUrl ? editingProduct.imageUrl : imageUrl}
                                    />
                                </Form.Group>
                            </Form.Row>

                            <Form.Control
                                placeholder="http://..."
                                defaultValue={imageUrl}
                                onChange={(e) => {
                                    this.setState({
                                        editingProduct: {
                                            ...editingProduct,
                                            imageUrl: e.target.value,
                                        }
                                    });
                                }}
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridTitle">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                placeholder="Title"
                                defaultValue={title}
                                onChange={(e) => this.setState({
                                    editingProduct: {
                                        ...editingProduct,
                                        title: e.target.value,
                                    }})
                                }
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridText">
                            <Form.Label>Text</Form.Label>
                            <Form.Control
                                placeholder="Text"
                                as="textarea"
                                rows="3"
                                defaultValue={text}
                                onChange={(e) => this.setState({
                                    editingProduct: {
                                        ...editingProduct,
                                        text: e.target.value,
                                    }})
                                }
                            />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPrice">
                            <Form.Label>Price</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">$</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    placeholder="Price"
                                    defaultValue={price}
                                    type="number"
                                    step="0.01"
                                    onChange={(e) => this.setState({
                                        editingProduct: {
                                            ...editingProduct,
                                            price: parseFloat(e.target.value),
                                        }})
                                    }
                                />
                            </InputGroup>

                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridCategory">
                            <Form.Label>Category</Form.Label>
                            <Form.Control as="select"
                                onChange={(e) => this.setState({ editingProduct: {...editingProduct, category: e.target.value } })}
                                defaultValue={category}
                            >
                                <option value={undefined}>Choose...</option>
                                { this.state.categories.map((cat) => <option  key={`opt_${cat}`} value={cat}>{cat}</option>) }
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>

                    { this._renderSelectedProductOptions(options) }
                </Form>

                <div
                    style={{ display: 'flex', justifyContent: 'flex-start' }}
                >
                    <Button
                        onClick={() => this.setState({ viewingProduct: undefined, editingProduct: undefined, })}
                    >
                        Back
                    </Button>

                    <Button
                        variant="success"
                        onClick={() => this._handleProductSaved(productId, editingProduct)}
                    >
                        Save
                    </Button>


                    <Button
                        variant='danger'
                        onClick={() => this._handleProductDelete(productId)}
                    >
                        Delete
                    </Button>
                </div>
            </>
        )
    }

    _renderSelectedProductOptions = (options) => {
        if (!options) {
            return (
                <>
                    <h4>Options</h4>
                    <div>No options for this product. Add one?</div>
                </>
            );
        }

        const { editingProduct } = this.state;

        const optionJsx = Object.keys(options).map((optionKey) => {
            const option = options[optionKey]; // is an array
            if (!option) {
                return null;
            }

            const optionValues = option ? option.join(',') : '';

            const formOverlayJsx = (
                <Popover id="popover-basic" title="Rename Option">
                    <Form>
                        <Form.Control
                            type="text"
                            defaultValue={optionKey}
                            onChange={(e) => {
                                this.setState({
                                    renamingCategory: e.target.value,
                                });
                            }}
                        />
                        <Button
                            onClick={() => {
                                let oldOptionValues = option;
                                const { renamingCategory } = this.state;

                                const newEditingProduct = {
                                    ...editingProduct,
                                    options: {
                                        ...options,
                                        [optionKey]: undefined,
                                        [renamingCategory]: oldOptionValues,
                                    },
                                }

                                this.setState({
                                    editingProduct: newEditingProduct,
                                    renamingCategory: undefined,
                                });
                            }}
                        >
                            Rename
                        </Button>
                    </Form>
                </Popover>
              );

            return (
                <div key={optionKey}>
                    <h6 style={{ display: 'inline' }}>{optionKey}</h6>

                    <OverlayTrigger trigger="click" placement="right" overlay={formOverlayJsx}>
                    <Button
                        variant="link"
                        size="sm"
                        style={{ display: 'inline' }}
                    >
                        Rename
                    </Button>

                    </OverlayTrigger>

                    <Form.Control
                        type="text"
                        defaultValue={optionValues}
                        onChangeCapture={(e) => {
                            let newValues = [];
                            let values = e.target.value.split(',');
                            values.forEach((v) => {
                                if (v && v.length) {
                                    newValues.push(v.trim())
                                }
                            });

                            const newEditingProduct = {
                                ...editingProduct,
                                options: {
                                    ...editingProduct.options,
                                    [optionKey]: newValues,
                                }
                            };

                            console.log('editing product', editingProduct);
                            this.setState({
                                editingProduct: newEditingProduct,
                            });
                        }}
                    />
                    <Form.Text className="text-muted">
                        Separate options by <pre style={{ display: 'inline'}}>,</pre>
                    </Form.Text>
                </div>
            );
        });

        return (
            <>
                <h4>Options</h4>
                { optionJsx }
                <Button
                    size="sm"
                    onClick={() => {
                        const newEditingProduct = {
                            ...editingProduct,
                            options: {
                                ...editingProduct.options,
                                'New Option': [],
                            },
                        };

                        this.setState({
                            editingProduct: newEditingProduct,
                        });
                    }}
                >
                    New Option
                </Button>
            </>
        );
    }

    _renderProductsForCategory = (category) => {
        const { products } = this.state;
        const filteredProducts = products.filter((product) => {
            return product.category === category;
        });

        const productJsx = filteredProducts.map((filteredProduct) => {
            const { title, text, imageUrl, productId, price } = filteredProduct;
            const isTextVisible = false;

            const width = 240;

            return (
                <Card
                    key={productId}
                    style={{ width: width, minWidth: width, maxWidth: width, marginBottom: 2 }}
                >
                    <Card.Img
                        variant="top"
                        src={imageUrl}
                    />
                    <Card.Body>
                        <Card.Title>{title}</Card.Title>
                        {
                            isTextVisible &&
                                <Card.Text>
                                    {text}
                                </Card.Text>
                        }
                    </Card.Body>

                    <Card.Footer>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                alignItems: 'baseline',
                            }}
                        >
                            <span>
                                ${ price.toFixed(2) }<span className="units">/bi-weekly</span>
                            </span>

                            <Button variant="primary" onClick={() => this._handleProductClicked(filteredProduct)}>Details</Button>
                        </div>
                    </Card.Footer>
                </Card>
            );
        });

        return (
            <>
                <CardDeck
                    style={{
                        justifyContent: 'center',
                    }}
                >
                    {productJsx}
                </CardDeck>
            </>
        );
    }

    _renderProductCategories = (products, activeCategory, categories) => {
        const { viewingProduct } = this.state;

        if (viewingProduct) {
            return null;
        }

        if (products.length === 0) {
            return <div><em>No products</em></div>;
        }

        const categoriesJsx = categories.map((category) => {
            return (
                <Tab
                    key={category}
                    eventKey={category}
                    title={category.replace(/-/g, ' ')}
                >
                    { this._renderProductsForCategory(category) }
                </Tab>
            );
        });

        return (
            <Tabs
                variant="tabs"
                defaultActiveKey={activeCategory}
                onSelect={key => this.setState({activeCategory: key})}
            >
                { categoriesJsx }
            </Tabs>
        );
    }

    render() {
        const {
            products,
            viewingProduct,
            editingProduct,
            newProduct,
            activeCategory,
            showAddDialog,
            newCategory,
            categories,
            showAddCategoryDialog,
        } = this.state;

        return (
            <div style={{ margin: '80px 0'}}>
            { this._renderProductCategories(products, activeCategory, categories) }
            { this._renderSelectedProductDetails(viewingProduct, editingProduct) }
            { this._renderNewProduct(newProduct, activeCategory, showAddDialog) }
            { this._renderAddNewCategory(showAddCategoryDialog, newCategory)}
        </div>
        )
    }
}