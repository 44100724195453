import React from 'react';

import Container from 'react-bootstrap/Container';
import { withRouter } from 'react-router-dom';

import { MainNav } from '../MainNav';
import { SiteFooter } from '../SiteFooter';
import { ProductsController } from '../../api/ProductsController';
import { ProductCardList } from '../ProductCardList';
import { ProductCategory } from '../../containers/ProductCategory';

class ProductCategoryClass extends React.Component {
    constructor(props, state) {
        super(props, state);

        this.state = {
            products: [],
        };

        this._fetchCategoryProducts();
    }

    _fetchCategoryProducts = () => {
        const { category } = this.props;

        if (category) {
            ProductsController
            .getProductsByCategory(category)
            .then((response) => {
                const { products } = response;
                this.setState({
                    products,
                })
            });
        } else {
            this.setState({
                products: [],
            });
        }
    }

    _handleProductCardClicked = (product) => {
        const { history } = this.props;
        history.push(`/product/${product.productId}`);
    }

    toProperCase = (string) => {
        return `${string.substring(0, 1).toUpperCase()}${string.substring(1)}`.replace(/-/g, ' ');
    }

    _renderCategoryProducts = (products) => {
        const { category } = this.props;
        if (!category) {
            return;
        }

        if (products.length) {
            return (
                <>
                    <h2>Products in: { this.toProperCase(category) }</h2>
                    <ProductCardList
                        products={products}
                        onClick={this._handleProductCardClicked}
                        isTextVisible={false}
                    />
                </>
            );
        }

        return <div>No products yet</div>
    }

    render() {
        return (
            <>
                <MainNav />
                <Container>
                    { this._renderCategoryProducts(this.state.products) }
                </Container>
                <SiteFooter />
            </>
        );
    }
}

export const ProductCategoryComponent = withRouter(ProductCategoryClass);