import React from 'react';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';

export class SiteFooter extends React.Component {
    render() {
        return (
            <Jumbotron fluid style={{ margin: 0, color: 'white', backgroundColor: '#343434'}}>
                <Container>
                    <h1>Sport Shopping</h1>
                    <p>
                        This is some very special disclaimer text, such as &quot;All products listed herein are legitimately sourced from qualified distributors. No transaction details will be collected. Purchases will be pre-qualified.
                    </p>
                </Container>
            </Jumbotron>
        );
    }
}