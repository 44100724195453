import React from 'react';

import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export class AdminNav extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { active } = this.props;

        return (
            <Navbar expand="lg">
                <Navbar.Brand href="#home">
                    Sport Shopping <strong>Admin</strong>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto" defaultActiveKey={`/admin/${active}`}>
                        <Nav.Link href="/">Site</Nav.Link>
                        <Nav.Link href="/admin/orders">Orders</Nav.Link>
                        <Nav.Link href="/admin/products">Products</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}