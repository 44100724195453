import React from 'react';

import { AdminOrderComponent } from '../components/Admin/Order';

export class AdminOrder extends React.Component {
    render() {
        return (
            <AdminOrderComponent />
        );
    }
}