import React from 'react';
import { ProductsController } from '../../api/ProductsController';
import { fetchCartIdFromStorage } from '../../api/Utils';

export const CartContext = React.createContext();

export class CartContextProvider extends React.Component {
    constructor(props, state) {
        super(props, state);

        this.state = {
            cart: {
                id: undefined,
                qty: undefined,
            },
        };
    }

    componentDidMount() {
        console.log('Cart Context Loaded');
        const id = fetchCartIdFromStorage();
        const qty = 0;

        this.setState({
            cart: {
                id,
                qty,
            }
        });
    }

    componentWillUpdate(nextProps, nextState) {
        const { cart: nextCart } = nextState;
        const { cart: prevCart } = this.state;

        if (
            prevCart
            && nextCart
            && nextCart.qty
            && nextCart.id
            && nextCart.qty === prevCart.qty
            && nextCart.id === prevCart.id
        ) {
            console.log('nothing changed.');
            return;
        }

        console.log('Cart context updated.');
        ProductsController
            .getCart(fetchCartIdFromStorage()).then((result) => {
                const { length, order } = result;

                if (length && order) {
                    this.setState({
                        cart: {
                            ...this.state.cart,
                            qty: length,
                            id: order.cartId,
                        }
                    });
                }
            });
    }

    _updateCart = (id, qty) => {
        this.setState({
            cart: {
                ...this.state.cart,
                id,
                qty,
            }
        });
    }

    _clearCart = () => {
        this.setState({
            cart: undefined,
        });
    }

    render() {
        return (
            <CartContext.Provider
                value={{
                    cart: this.state.cart,
                    updateCart: this._updateCart,
                    clearCart: this._clearCart,
                }}
            >
                { this.props.children }
            </CartContext.Provider>
        )
    }
}