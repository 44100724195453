import React from 'react';


import { CartComponent } from '../components/Cart';

export class Cart extends React.Component {
    // TODO: should we actually pass in cartId?

    render() {
        return (
            <CartComponent />
        );
    }
}