import React from 'react';

export class ProductOptions extends React.Component {
    constructor(props, state) {
        super(props, state);

    }

    _renderOptions = () => {
        const { productOptions } = this.props;

        if (!productOptions) {
            return <em>No options to choose from.</em>;
        }


        return Object.keys(productOptions).map((optionKey) => {
            const option = productOptions[optionKey];

            return (
                <div
                    key={optionKey}
                    style={{ padding: '8px 0'}}
                >
                    <h6>{optionKey.toUpperCase()}</h6>
                    <select onChange={(e) => this._onOptionChanged(e, optionKey)}>
                        {
                            [''].concat(Object.values(option)).map((val) => {
                                return (
                                    <option
                                        key={`${optionKey}_${val}`}
                                        value={val}
                                    >{val}</option>
                                );
                            })
                        }
                    </select>
                </div>
            );
        });
    }

    _onOptionChanged = (e, o) => {
        const { value } = e.target;
        this.props.onOptionChanged(o, value);
    }

    render() {
        return (
            <div style={{ padding: '16px 0'}}>
                <h4>Product Options</h4>
                { this._renderOptions() }
            </div>
        );
    }
}