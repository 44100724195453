import React from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { ProductsController } from '../../api/ProductsController';
import { clearCartIdFromStorage } from '../../api/Utils';

export class CartCheckout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      phone: undefined,
      addr1: undefined,
      addr2: undefined,
      city: undefined,
      region: undefined,
      postal: undefined,
      isAgree: false,

      isLoading: false,
    }
  }

  _canSubmit = (conditions) => {
    const canSubmit = conditions.every((condition) => condition);

    if (canSubmit) {
      console.log('Everything is okay to submit.');
    } else {
      console.log('No bueno');
    }

    return canSubmit;
  }

  _handleSubmit = (e) => {
    e.preventDefault();

    const {
      firstName,
      lastName,
      email,
      phone,
      addr1,
      addr2,
      city,
      region,
      postal,
      isAgree,
    } = this.state;

    if (!isAgree) {
      return;
    }

    const payload = {
      firstName,
      lastName,
      email,
      phone,
      addr1,
      addr2,
      city,
      region,
      postal,
    };

    const { cartId } = this.props;
    // todo: update cart context with cart data

    this.setState({
      isLoading: true,
    });

    ProductsController.checkout(payload, cartId).then((result) => {
      if (result && result.checkout) {
        // successful checkout!

        // clear data
        this.setState({
          firstName: undefined,
          lastName: undefined,
          email: undefined,
          phone: undefined,
          addr1: undefined,
          addr2: undefined,
          city: undefined,
          region: undefined,
          postal: undefined,
          isAgree: false,
        });
        // clear cartId
        clearCartIdFromStorage(this.props.cartId);
        // Display message
        this.props.onCheckoutComplete();
        window.alert("Your checkout is complete! We will contact you shortly to finalize your order.");
      } else {
        window.alert("There was an issue with your submission. Try again later, or contact support.");
      }
    });
  }

  render() {

    const {
      firstName,
      lastName,
      email,
      phone,
      addr1,
      addr2,
      city,
      region,
      postal,
      isAgree,
    } = this.state;

    const conditions = [firstName, lastName, email, addr1, city, region, postal, isAgree];

    return (
      <Form>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              placeholder="First Name"
              onChange={(e) => this.setState({ firstName: e.target.value })}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              placeholder="Last name"
              onChange={(e) => this.setState({ lastName: e.target.value })}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={(e) => this.setState({ email: e.target.value })}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPhone">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="tel"
              placeholder="Phone"
              onChange={(e) => this.setState({ phone: e.target.value })}
            />
          </Form.Group>
        </Form.Row>

        <Form.Group controlId="formGridAddress1">
          <Form.Label>Address</Form.Label>
          <Form.Control
            placeholder="1234 Main St"
            onChange={(e) => this.setState({ addr1: e.target.value })}
          />
        </Form.Group>

        <Form.Group controlId="formGridAddress2">
          <Form.Label>Address 2</Form.Label>
          <Form.Control
            placeholder="Apartment, studio, or floor"
            onChange={(e) => this.setState({ addr2: e.target.value })}
          />
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>City</Form.Label>
            <Form.Control
              placeholder="Regina, Vancouver, Toronto, Winnipeg"
              onChange={(e) => this.setState({ city: e.target.value })}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridRegion">
            <Form.Label>Province/Region</Form.Label>
            <Form.Control as="select"
              onChange={(e) => this.setState({ region: e.target.value })}
            >
              <option>Choose...</option>
              <option value="SK">Saskatchewan</option>
              <option value="MB">Manitoba</option>
              <option value="AB">Alberta</option>
              <option value="BC">British Columbia</option>
              <option value="ON">Ontario</option>
              <option value="QC">Quebec</option>
              <option value="NS">Nova Scotia</option>
              <option value="NB">New Brunswick</option>
              <option value="PE">Prince Edward Island</option>
              <option value="NL">Newfoundland/Labrador</option>
              <option value="NT">North West Territories</option>
              <option value="YT">Youkon</option>
              <option value="NU">Nunavut</option>
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPostalCode">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              placeholder=""
              onChange={(e) => this.setState({ postal: e.target.value })}
            />
          </Form.Group>
        </Form.Row>

        <Form.Group id="formGridCheckbox">
          <p>
            I understand that terms and conditions and terms and conditions and terms and conditions and terms and conditions and terms and conditions and terms and conditions and terms and conditions and terms and conditions and terms and conditions and conditions and terms and conditions and terms and conditions and terms and conditions and terms and conditions and terms and conditions and conditions and terms and conditions and terms and conditions and terms and conditions and terms and conditions and terms and conditions.
          </p>
          <Form.Group
            controlId="agree"
          >
            <Form.Check
              type="checkbox"
              size="lg"
              label="I Agree"
              onChange={(e) => this.setState({ isAgree: e.target.checked })}
            />
          </Form.Group>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          size="lg"
          disabled={!this._canSubmit(conditions)}
          onClick={this._handleSubmit}
        >
          Submit Order
        </Button>
      </Form>
    );
  }
}
