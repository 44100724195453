import React from 'react';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

import { SiteFooter } from '../SiteFooter';
import { MainNav } from '../MainNav';
import { ProductsController } from '../../api/ProductsController';

import { ProductOptions } from '../ProductOptions';
import { CartContext } from '../CartContext';
import { fetchCartIdFromStorage, setCartIdToStorage } from '../../api/Utils';

export class ProductDetailsComponent extends React.Component {
    static contextType = CartContext;

    constructor(props, state) {
        super(props, state);

        this.state = {
            isPageLoading: true,
            product: undefined,
            options: {},
            showSuccessDialog: false,
            showErrorDialog: false,
        };
    }

    componentWillMount() {
        this._fetchProductDetails();

        this.setState({
            cartId: fetchCartIdFromStorage(),
        });
    }

    _fetchProductDetails = () => {
        ProductsController.getProductById(this.props.productId).then((result) => {
            const { product } = result;
            if (!product) {
                return;
            }

            let options = {};

            if (product.options) {
                Object.keys(product.options).forEach((optionKey) => {
                    options[optionKey] = null;
                });
            }

            this.setState({
                product: result.product,
                isPageLoading: false,
                options,
            });
        });
    }

    _isCardButtonEnabled = () => {
        const { options } = this.state;

        return options && Object.keys(options).every((optionKey) => options[optionKey]);
    }

    _handleOptionsChanged = (option, value) => {
        let { options } = this.state || {};

        options = {
            ...options,
            [option]: value,
        };

        this.setState({
            options,
        });
    }

    _handleAddToCartClicked = () => {
        this.setState({
            isCartLoading: true,
        });

        const { productId } = this.props;
        const { options, cartId } = this.state;

        ProductsController.addProductToCart({
            product: {
                productId,
                options,
            }
        }, cartId).then((response) => {
            const { order } = response;

            if (!order) {
                this.setState({
                    isCartLoading: false,
                    showSuccessDialog: false,
                    showErrorDialog: true,
                });
                return;
            }

            const { cartId, length } = order;
            setTimeout(() => {
                this.setState({
                    isCartLoading: false,
                    showSuccessDialog: true,
                    cartId,
                }, () => {
                    this.context.updateCart(cartId, length);
                    setCartIdToStorage(cartId);
                });
            }, 250);
        }).catch((error) => {
            console.log('Error', error);
            this.setState({
                isCartLoading: false,
                showSuccessDialog: false,
                showErrorDialog: true,
            })
        });
    }

    _renderProduct = (product, isCartLoading) => {
        if (!product) {
            return <div>No such product</div>;
        }

        const { productId, text, title, price, imageUrl, options } = product;
        if (!productId) {
            return <div>No such product</div>;
        }

        return (
            <>
                <Row>
                    <Col>
                        <h1>{title}</h1>
                    </Col>
                </Row>
                <Row style={{ padding: 16 }}>
                    <Col style={{ display: 'flex' }}>
                        <img src={imageUrl} style={{ width: '85%', height: 'auto', maxWidth: 350, margin: '0 auto' }}/>
                    </Col>
                    <Col>
                        { text }
                        {/* TODO: product options */}
                        <ProductOptions
                            productOptions={options}
                            onOptionChanged={this._handleOptionsChanged}
                        />

                        {/* TODO: qty selector */}
                        <h2>${ price.toFixed(2) }<span className="units">/bi-weekly</span></h2>
                        <Button
                            onClick={this._handleAddToCartClicked}
                            ref={this.buttonRef}
                            disabled={!this._isCardButtonEnabled()}
                        >
                            { isCartLoading ? <Spinner animation="border" variant="light" /> : <span>Add to Cart</span> }
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }

    _renderModal = (show) => {
        const { product, showSuccessDialog, showErrorDialog } = this.state;

        if (!product) {
            return null;
        }

        const handleClose = () => this.setState({
            showErrorDialog: false,
            showSuccessDialog: false
        });

        return (
            <Modal
                show={show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        { showSuccessDialog ? 'Added to Cart' : 'Error' }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        showSuccessDialog
                        ? <>{product.title} added to cart.</>
                        : <>There was an error. Try again.</>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant={showSuccessDialog ? 'success' : 'danger'}
                        onClick={handleClose}
                    >
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    render() {
        const { product, isPageLoading, isCartLoading, showSuccessDialog } = this.state;

        if (isPageLoading) {
            return (
                <div>
                    Loading...
                </div>
            );
        }

        const productJsx = this._renderProduct(product, isCartLoading, showSuccessDialog);
        const successDialogJsx = this._renderModal(showSuccessDialog);

        return (
            <div>
                <MainNav />

                { successDialogJsx }

                <Container
                    style={{minHeight: '80vh'}}
                >
                    { productJsx }
                </Container>
                <SiteFooter />
            </div>
        );
    }
}
