import React from 'react';
import Container from 'react-bootstrap/Container';

import { SiteFooter } from '../SiteFooter';
import { HomeJumbotron } from '../HomeJumbo';

import { AdminNav } from './Nav';
import { ProductsList } from './ProductsList';

export class AdminProductsComponent extends React.Component {
    render() {
        return (
            <>
                <AdminNav
                    active="products"
                />
                <HomeJumbotron />

                <Container>
                    <ProductsList />
                </Container>

                <SiteFooter />
            </>
        );
    }
}
