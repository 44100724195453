const headers = {
    'user-agent': 'sportshopping-web v1.0',
};

export const ProductsController = {
    /**
     * List products.
     * Takes a variant, eg, 'all', 'featured', etc
     */
    listProducts: (variant) => {
        const URL = `/api/products/${variant || ''}`;

        const options = {
            method: 'get',
            mode: 'cors',
            headers,
        };

        return fetch(URL, options)
            .then((apiResponse) => {
                return apiResponse.json();
            }).then((parsedApiResponse) => {
                // filter out products with no productId
                const { products } = parsedApiResponse;
                parsedApiResponse.products = products.filter((product) => product.productId);
                return parsedApiResponse;
            }).catch((err) => {
                return {};
            });
    },

    /**
     * List categories
     */
    listCategories: () => {
        const URL = '/api/categories';
        const options = {
            method: 'get',
            mode: 'cors',
            headers,
        };

        // TODO: maybe cache this response...
        return fetch(URL, options)
            .then((apiResponse) => {
                return apiResponse.json();
            }).then((parsedApiResponse) => {
                return parsedApiResponse;
            }).catch((err) => {
                return [];
            });
    },

    listOrders: () => {
        const URL = '/api/carts';
        const options = {
            method: 'get',
            mode: 'cors',
            headers,
        };

        return fetch(URL, options)
            .then((apiResponse) => {
                return apiResponse.json();
            }).then((parsedApiResult) => {
                return parsedApiResult;
            }).catch((err) => {
                return [];
            });
    },

    /**
     * Gets product details by its product id
     */
    getProductById: (productId) => {
        const URL = `/api/product/${productId}`;
        const options = {
            method: 'get',
            mode: 'cors',
            headers,
        };

        return fetch(URL, options)
            .then((apiResponse) => {
                return apiResponse.json();
            }).then((parsedApiResponse) => {
                return parsedApiResponse;
            }).catch((err) => {
                return {};
            });
    },

    addProduct: (newProduct) => {
        const URL = `/api/products`;
        const options = {
            method: 'post',
            mode: 'cors',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ newProduct }),
        };

        return fetch(URL, options)
            .then((apiResponse) => {
                return apiResponse.json();
            }).then((parsedApiResponse) => {
                return parsedApiResponse;
            }).catch((err) => {
                return {};
            });
    },

    updateProductById: (productId, changes) => {
        const URL = `/api/product/${productId}`;
        const options = {
            method: 'put',
            mode: 'cors',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ changes }),
        };

        return fetch(URL, options)
        .then((apiResponse) => {
            return apiResponse.json();
        }).then((parsedApiResponse) => {
            return parsedApiResponse;
        }).catch((err) => {
            return {};
        });
    },

    /**
     * Get products by category
     */
    getProductsByCategory: (category) => {
        const URL = `/api/products/category?category=${category.toLowerCase()}`;

        const options = {
            method: 'get',
            mode: 'cors',
            headers,
        };

        return fetch(URL, options)
            .then((apiResponse) => {
                return apiResponse.json();
            }).then((parsedApiResponse) => {
                // filter out invalid products
                const { products } = parsedApiResponse;

                parsedApiResponse.products = products.filter((product) => product.productId );

                return parsedApiResponse;
            }).catch((err) => {
                return {};
            });
    },

    getCart: (cartId) => {
        const URL = `/api/cart/${cartId}`;
        const options = {
            method: 'get',
            mode: 'cors',
            headers,
        };

        return fetch(URL, options)
            .then((apiResponse) => {
                return apiResponse.json();
            }).then((parsedApiResponse) => {
                return parsedApiResponse;
            }).catch((err) => {
                return err;
            });
    },

    /**
     * Add a product to cart. Returns cart, and a cartId
     */
    addProductToCart: (product, cartId) => {
        const URL = `/api/cart/${cartId || ''}`;
        const options = {
            method: 'post',
            mode: 'cors',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(product),
        };

        return fetch(URL, options)
            .then((apiResponse) => {
                return apiResponse.json();
            }).then((parsedApiResponse) => {
                return parsedApiResponse;
            }).catch((err) => {
                return err;
            });
    },

    deleteProductById: (productId) => {
        const URL = `/api/product/${productId}`;
        const options = {
            method: 'delete',
            mode: 'cors',
            headers,
        };

        return fetch(URL, options)
            .then((apiResponse) => {
                return apiResponse.json();
            })
            .then((parsedApiResponse) => {
                return parsedApiResponse;
            })
            .catch((err) => {
                return err;
            });
    },

    addCategory: (newCategory) => {
        const URL = `/api/categories`;
        const options = {
            method: 'post',
            mode: 'cors',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ newCategory }),
        };

        return fetch(URL, options)
            .then((apiResponse) => {
                return apiResponse.json();
            })
            .then((parsedApiResponse) => {
                return parsedApiResponse;
            })
            .catch((err) => {
                return err;
            });
    },

    checkout: (payload, cartId) => {
        const URL = `/api/cart/${cartId}`;
        const options = {
            method: 'put',
            mode: 'cors',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };

        return fetch(URL, options)
            .then((apiResponse) => {
                return apiResponse.json();
            }).then((parsedApiResponse) => {
                return parsedApiResponse;
            }).catch((err) => {
                return err;
            });
    },

    fulfillOrder: (orderId) => {
        const URL = `/api/cart/${orderId}/fulfill`;
        const options = {
            method: 'post',
            mode: 'cors',
            headers: {
                ...headers,
                'Content-Type': 'application/json',
            },
        };

        return fetch(URL, options)
            .then((apiResponse) => {
                return apiResponse.json();
            })
            .then((parsedApiResponse) => {
                return parsedApiResponse;
            })
            .catch((err) => {
                return err;
            });
    },

    deleteIndexFromCart: (index, cartId) => {
        const URL = `/api/cart/${cartId}/${index}`;
        const options = {
            method: 'delete',
            mode: 'cors',
            headers
        };

        return fetch(URL, options)
            .then((apiResponse) => {
                return apiResponse.json();
            }).then((parsedApiResponse) => {
                return parsedApiResponse;
            }).catch((err) => {
                return err;
            });
    },

    /**
     * Delete a cart
     */
    deleteCart: (cartId) => {
        const URL = `/api/cart/${cartId}`;
        const options = {
            method: 'delete',
            mode: 'cors',
            headers,
        };

        return fetch(URL, options)
            .then((apiResponse) => {
                return apiResponse.json();
            })
            .then((parsedApiResponse) => {
                return parsedApiResponse;
            })
            .catch((err) => {
                return err;
            });
    },
}