import React from 'react';

import { ProductDetailsComponent } from '../components/ProductDetails';

export class ProductDetails extends React.Component {
    productId = null;

    constructor(props, state) {
        super(props, state);

        const { match } = props;
        const { params } = match;
        const { productId } = params;

        console.log(props);
        this.productId = productId;
    }

    render() {
        return (
            <ProductDetailsComponent
                productId={this.productId}
            />
        );
    }
}